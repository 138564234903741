<template>
  <div>
    <div>
      <div class="row">
        <div class="col-lg-6">
          <b-skeleton-wrapper :loading="loading">
            <template #loading>
              <div
                class="border-raduis-md bg-white box-shadow-xl-dark p-4"
                style="height: 240px"
              >
                <div class="title d-flex justify-content-between align-center">
                  <b-skeleton
                    variant="light"
                    height="30px"
                    width="60%"
                  ></b-skeleton>

                  <b-skeleton
                    variant="light"
                    height="30px"
                    width="48px"
                  ></b-skeleton>
                </div>
                <div class="card mt-4">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item small">
                      <b-skeleton
                        variant="light"
                        height="15px"
                        width="100%"
                      ></b-skeleton>
                    </li>
                    <li class="list-group-item small">
                      <b-skeleton
                        variant="light"
                        height="15px"
                        width="100%"
                      ></b-skeleton>
                    </li>
                    <li class="list-group-item small">
                      <b-skeleton
                        variant="light"
                        height="15px"
                        width="100%"
                      ></b-skeleton>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <statistic-widget
              :value="this.computedWork"
              :label="$t('works_number')"
              :volumeCumac="true"
              :cumacValue="this.totalVolume"
              ctaText="Voir tout"
              URL="Works"
            ></statistic-widget>
          </b-skeleton-wrapper>
        </div>
        <div class="col-lg-3">
          <b-skeleton-wrapper :loading="loading">
            <template #loading>
              <div
                class="border-raduis-md bg-white box-shadow-xl-dark p-4"
                style="height: 240px"
              >
                <div class="title d-flex justify-content-between align-center">
                  <b-skeleton
                    variant="light"
                    height="30px"
                    width="60%"
                  ></b-skeleton>

                  <b-skeleton
                    variant="light"
                    height="30px"
                    width="48px"
                  ></b-skeleton>
                </div>
                <div class="card mt-4">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item small">
                      <b-skeleton
                        variant="light"
                        height="15px"
                        width="100%"
                      ></b-skeleton>
                    </li>
                    <li class="list-group-item small">
                      <b-skeleton
                        variant="light"
                        height="15px"
                        width="100%"
                      ></b-skeleton>
                    </li>
                    <li class="list-group-item small">
                      <b-skeleton
                        variant="light"
                        height="15px"
                        width="100%"
                      ></b-skeleton>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <statistic-widget
              :value="this.simulationCount"
              :label="$t('simulation_number')"
              ctaText="Voir tout"
              URL="Simulations"
            ></statistic-widget>
          </b-skeleton-wrapper>
        </div>
        <div class="col-lg-3">
          <chart-widget ctaText="Voir tout" URL="Works"></chart-widget>
        </div>
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col-lg-9">
          <table-widget :title="$t('work_list')" URL="Works"></table-widget>
        </div>
        <div class="col-lg-3">
          <team-widget title="Vos contacts" URL="Contacts"></team-widget>
        </div>
      </div>
    </div>
    <div>
      <div>
        <div class="col-lg-12 giga-margin_t30" style="padding: 0">
          <b-skeleton-wrapper :loading="loading">
            <template #loading>
              <div
                class="border-raduis-md bg-white box-shadow-xl-dark p-4"
                style="height: 240px"
              >
                <div class="title d-flex justify-content-between align-center">
                  <b-skeleton
                    variant="light"
                    height="30px"
                    width="60%"
                  ></b-skeleton>

                  <b-skeleton
                    variant="light"
                    height="30px"
                    width="48px"
                  ></b-skeleton>
                </div>
                <div class="card mt-4">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item small">
                      <b-skeleton
                        variant="light"
                        height="15px"
                        width="100%"
                      ></b-skeleton>
                    </li>
                    <li class="list-group-item small">
                      <b-skeleton
                        variant="light"
                        height="15px"
                        width="100%"
                      ></b-skeleton>
                    </li>
                    <li class="list-group-item small">
                      <b-skeleton
                        variant="light"
                        height="15px"
                        width="100%"
                      ></b-skeleton>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <div class="giga-widget_header">
              <h3 class="giga-widget_title">Opérations non valorisées</h3>
            </div>
          </b-skeleton-wrapper>
        </div>
        <operation-widget></operation-widget>
      </div>
    </div>
  </div>
</template>

<script>
import StatisticWidget from "./widgets/StatisticWidget.vue";
import tableWidget from "./widgets/tableWidget";
import teamWidget from "./widgets/teamWidget";
import chartWidget from "./widgets/chartWidget.vue";
import { mapActions, mapGetters } from "vuex";
import OperationWidget from "./widgets/OperationWidget";
import { constants } from "@/shared/constants";
import { helpers } from "@/shared/helper";

export default {
  name: "GeneralDashboard",
  components: {
    OperationWidget,
    StatisticWidget,
    tableWidget,
    teamWidget,
    chartWidget
  },
  computed: {
    ...mapGetters("work", {
      workCount: "getWorkCount",
      computedWorkCount: "getOperationsCount",
      totalVolume: "getTotalVolume"
    }),
    ...mapGetters("simulation", { simulationCount: "getSimulationCount" }),
    ...mapGetters("loader", { loading: "getLoading" }),
    ...mapGetters("account", { currentUser: "getCurrentUser" }),
    computedWork() {
      if (this.currentUser.clientType === this.USER_TYPE.ARTISAN) {
        return this.computedWorkCount;
      }
      return this.workCount;
    },
    isNotB2B2B() {
      return helpers.isNotB2B2B();
    }
  },
  mounted() {
    if (this.currentUser.clientType === this.USER_TYPE.ARTISAN) {
      this.getWorkOperationsCountAction();
      this.getWorkCountAction();
      this.getSimulationCountAction();
      this.getTotalVolume();
    } else if (
      this.currentUser.clientType === this.USER_TYPE.B2B2B &&
      this.currentUser.role?.roleType === this.ROLE_TYPE.ADMIN_AGENCE
    ) {
      this.getWorkCountActionAdminAgence({
        agenceId: this.currentUser.agence?.id
      });
      this.getSimulationCountActionAdminAgence({
        agenceId: this.currentUser.agence?.id
      });
      this.getTotalVolumeAdminAgence({ agenceId: this.currentUser.agence?.id });
    } else if (
      (this.currentUser.clientType === this.USER_TYPE.B2B2B &&
        this.currentUser.role?.roleType === this.ROLE_TYPE.USER) ||
      (this.isNotB2B2B &&
        this.currentUser.role?.roleType === this.ROLE_TYPE.USER)
    ) {
      this.getWorkCountActionChargeDeProjet();
      this.getSimulationCountActionChargeDeProjet();
      this.getTotalVolumeChargeDeProjet();
    } else {
      this.getWorkCountAction();
      this.getSimulationCountAction();
      this.getTotalVolume();
    }
  },
  methods: {
    ...mapActions("work", [
      "getWorkCountAction",
      "getTotalVolume",
      "getTotalVolumeAdminAgence",
      "getTotalVolumeChargeDeProjet",
      "getWorkCountActionAdminAgence",
      "getWorkCountActionChargeDeProjet",
      "getWorkOperationsCountAction"
    ]),
    ...mapActions("simulation", [
      "getSimulationCountAction",
      "getSimulationCountActionAdminAgence",
      "getSimulationCountActionChargeDeProjet"
    ])
  },
  data() {
    return {
      USER_TYPE: constants.USER_TYPE,
      ROLE_TYPE: constants.ROLE_TYPE
    };
  }
};
</script>
