<template>
  <div class="giga-doughnut_widget">
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <div
            class="border-raduis-md bg-white box-shadow-xl-dark p-4"
            style="height: 240px"
        >
          <div
              class="title d-flex justify-content-between align-center"
          >
            <b-skeleton
                variant="light"
                height="30px"
                width="60%"
            ></b-skeleton>

            <b-skeleton
                variant="light"
                height="30px"
                width="48px"
            ></b-skeleton>
          </div>
          <div class="card mt-4">
            <ul class="list-group list-group-flush">
              <li class="list-group-item small">
                <b-skeleton
                    variant="light"
                    height="15px"
                    width="100%"
                ></b-skeleton>
              </li>
              <li class="list-group-item small">
                <b-skeleton
                    variant="light"
                    height="15px"
                    width="100%"
                ></b-skeleton>
              </li>
              <li class="list-group-item small">
                <b-skeleton
                    variant="light"
                    height="15px"
                    width="100%"
                ></b-skeleton>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <div class="giga-widget_body">
        <b-button :to="{ name: URL }" class="giga-widget_seeMore">{{
          ctaText
        }}</b-button>
        <doughnut-widget v-if="this.chartData.length === 3" :chartdata="this.chartData"></doughnut-widget>
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import doughnutWidget from "../../../components/charts/doughnutWidget.vue";
import {ReportingService} from "../../Reporting/services";
import {mapGetters} from "vuex";

export default {
  name: "chartWidget",
  components: { doughnutWidget },
  props: {
    ctaText: {
      type: String,
    },
    URL: {
      type: String,
    },
  },
  data() {
    return {
      chartData: []
    }
  },
  computed: {
    ...mapGetters('loader', { loading: 'getLoading' })
  },
  mounted() {
    ReportingService.getWorkVolumeByStatusReport().then(res => {
      this.chartData = this.prepareData(res);
    });
  },
  methods: {
    prepareData(res) {
      const data = ['0', '0', '0'];
      res.forEach(r => {
        switch (r.status) {
          case 'DELIVERED':
            data[0] = r.volume;
            break;
          case 'IN_PROGRESS':
            data[1] = r.volume;
            break;
          case 'DEPOSITED':
            data[2] = r.volume;
            break;
        }
      });
      return data;
    }
  }
};
</script>

<style lang="scss">
.giga-doughnut_widget {
  .giga-widget_body {
    padding: 20px 10px;
  }
  canvas {
    height: 102px !important;
  }
}
</style>